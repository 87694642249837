import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styles from "./Home.module.scss";
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import Footer from "../Footer/Footer";
import foire from "../../assets/images/FOIRE.png";
import rbnet from "../../assets/images/reformed-baptist-network-logo.png";
import ProgressBar from "../ProgressBar/ProgressBar";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import BaseService from "../../services/BaseService";
import ReactDOM from "react-dom";
import { Parser } from "html-to-react";
import Button from "@material-ui/core/Button";
import GA from "../GA/GA";
import { ServicesService } from "../../services/ServicesService";
import rbs from "../../assets/images/Creation-Covenant-Christ.png";
import creation from "../../assets/images/Creation-2023.jpg";
import christmas from "../../assets/images/christmas-carol-2023.jpg";


class Home extends Component {
  baseService = new BaseService();
  servicesService = new ServicesService();

  state = {
    sideDrawerOpen: false,
    loading: true,
    announcement: "",
    liveStreamDescription: "",
    liveStreamVideo: "",
    services: {}
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  async componentDidMount() {
    this.setState({ loading: false });

    const result = await this.baseService.get("/home");
    const services = await this.servicesService.getAllService();

    const { announcement, live_stream_description, youtube_video_code } = result[0];
    this.setState({
      announcement,
      liveStreamDescription: live_stream_description,
      liveStreamVideo: youtube_video_code,
      services
    });
    ReactDOM.hydrate(new Parser().parse(this.state.announcement), document.getElementById("announcement"));
  }

  render() {
    return (
      <div className={styles.home}>
        <Helmet>
          <title>Reformed Baptist Church of Riverside</title>
          <link rel="canonical" href="http://www.rbcr.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Reformed Baptist Church of Riverside" />
          <meta property="og:url" content={`http://www.rbcr.org/`} />
          <meta property="og:site_name" content="Reformed Baptist Church of Riverside" />
        </Helmet>
        <Header drawerClickHandler={this.drawerToggleClickHandler} />
        <Hero />
        <div>
          <div className={styles.container}>
            {/* <div className={styles.spotlightGrid}> */}
              {/* <div className={styles.refGrid_2}>
              <h2 className={styles.videoGrid_title}>Womanly Dominion Mini Conference</h2>
                 <p>Come join us for the Womanly Dominion Mini Conference: More Than A Gentle and Quiet Spirit, with guest speaker Pastor Mark Chanski.</p>
                 <p>Feburary 10, 2024 10:00 AM - 3:00 PM</p>
                 <p>Click <a href="/womens-conference">here</a> for more information and <a href="https://forms.gle/kDbahFi4DSv4akui7" target="_blank">Register today</a></p>
              </div>
              <div className={styles.refGrid_1}>
                <a href="/womens-conference">
                  <img src="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/01/05/Screen_Shot_2024_01_05_at_10_42_33_PM_f53d552de9-Screen%20Shot%202024-01-05%20at%2010.42.33%20PM.png" alt="Womanly Dominion Mini Conference" />
                </a>
              </div> */}
             {/* <div className={styles.rbsGrid_2}>
               <h2 className={styles.videoGrid_title}>Christmas Carol Service</h2>
               <p>Come join us for an evening of singing Christmas Carols that point us to the true meaning of Christmas. Afterwards, we'll enjoy some home made soups and desserts. Invite your friends, family, and neighbors.</p>
               <p>December 17 - 4:30 PM - Soup/Dessert to follow</p>
             </div>
             <div className={styles.grid2}>
               <img src={christmas} alt="Christmas Carol" />
             </div> */}
            {/*</div>*/}
            {/* <div className={styles.spotlightGrid}>
              <div className={styles.rbsGrid_1}>
                <a href="/youth-conference">
                  <img src="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/04/25/rbcr_youth_2024_4e8e51a766-rbcr-youth-2024.png" alt="Youth Conference" />
                </a>
              </div>
              <div className={styles.rbsGrid_2}>
                <h2 className={styles.videoGrid_title}>Youth Conference {new Date().getFullYear()}</h2>
                <p>Join us June 28-30 for our annual youth conference where we will be discussing the topic of the <i>Treasuring Jesus Christ</i>.</p>
                <p><a href="/youth-conference">View More</a></p>
              </div>
            </div> */}
            <div className={styles.spotlightGrid}>
              <div className={styles.rbsGrid_1}>
                <a href="https://reformedbaptistnetwork.com/conference-2024">
                  <img src="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/08/16/2024_GA_Packet_cover_6f14cb40d7-2024%20GA%20Packet%20cover.jpg" alt="RBNET" />
                </a>
              </div>
              <div className={styles.rbsGrid_2}>
                <h2 className={styles.videoGrid_title}>Reformed Baptist Network General Assembly 2024</h2>
                <p>We are happy to be hosting this year’s GA at our church on September 15-17</p>
                <p>Learn more about it by clicking <a href="https://reformedbaptistnetwork.com/conference-2024">here</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.welcome}>
          <div className={styles.container}>
            <ScrollAnimation animateIn="fadeInLeft">
              <div className={styles.welcome__content}>
                <p>
                  e’re glad you are here and taking the time to get to know us better.
                  <br></br>Reformed Baptist Church of Riverside is a body of believers desiring to grow in the grace and
                  knowledge of our Lord Jesus Christ. We trust that this website will share a bit of who we are, what we
                  believe, and how you can get involved. So look around. Get a feel for what we believe and where we are
                  going. If you have any questions, please feel free to contact us.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className={styles.textureBg}>
          <div className={styles.container}>
            <div className={styles.announcement}>
              <blockquote id="announcement" />
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <ScrollAnimation animateIn="fadeIn">
            <div className={styles.videoGrid}>
              <div className={styles.videoGrid_1}>
                <h2 className={styles.videoGrid_title}>Our Latest Live Stream</h2>
                <hr className={styles.vg_hr} />
                <p className={styles.videoGrid_paragraph}>{this.state.liveStreamDescription}</p>
              </div>
              <div className="embed-responsive embed-responsive-16by9">
                {this.state.liveStreamVideo && (
                  <iframe
                    src={`https://www.youtube.com/embed/${this.state.liveStreamVideo}`}
                    className="embed-responsive-item"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </div>
            </div>
            <hr />
            <div className={styles.videoGrid}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/46077594"
                  className="embed-responsive-item"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className={styles.videoGrid_2}>
                <h2 className={styles.videoGrid_title}>Who we are</h2>
                <hr className={styles.vg_hr} />
                <p className={styles.videoGrid_paragraph}>
                  Want to learn more about who we are? Then please watch this short video by Pastor Robert Elliott.
                </p>
                <Link to="/about-rbcr/what-we-believe/">
                  <Button variant="contained" size="large" color="primary">
                    Learn more
                  </Button>
                </Link>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <div className={styles.churchGrid}>
              <div className={styles.outerDiv}>
                <div className={styles.churchGrid_1}>
                  <a href="/sermons" className={styles.sermons_a} rel="noopener noreferrer">
                    <h2 className={styles.churchGrid_title}>Sermons</h2>
                  </a>
                </div>
              </div>
              <div className={styles.outerDiv}>
                <div className={styles.churchGrid_2}>
                  <Link to="/about-rbcr/the-baptist-confession-of-faith-of-1689/" className={styles.sermons_a}>
                    <h2 className={styles.churchGrid_title}>1689 London Baptist Confession</h2>
                  </Link>
                </div>
              </div>
              <div className={styles.outerDiv}>
                <div className={styles.churchGrid_3}>
                  <Link to="/about-rbcr/church-constitution/" className={styles.sermons_a}>
                    <h2 className={styles.churchGrid_title}>Church Constitution</h2>
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        <div className={styles.churchService}>
          <div className={styles.container}>
            <div className={styles.timeGrid_1}>
              <ScrollAnimation animateIn="fadeInRight">
                <h3 className={styles.churchService_title}>Come Join Us</h3>
                <hr className={styles.cs_hr} />
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeIn">
              <p className={styles.meetingHeader}>Sunday</p>
              <div className={styles.timeGrid}>
                <div className={styles.timeGrid_1}>
                  <p className={styles.timeCircle}>{this.state.services.spanish}</p>
                  <p className={styles.timeDay}>Spanish Ministry</p>
                </div>
                <div className={styles.timeGrid_1}>
                  <p className={styles.timeCircle}>{this.state.services.sundaySchool}</p>
                  <p className={styles.timeDay}>Sunday School</p>
                </div>
                <div className={styles.timeGrid_1}>
                  <p className={styles.timeCircle}>{this.state.services.morning}</p>
                  <p className={styles.timeDay}>Sunday Worship</p>
                </div>
                <div className={styles.timeGrid_1}>
                  <p className={styles.timeCircle} style={{margin: "5px auto 14px auto"}}>{this.state.services.evening}</p>
                  <p className={styles.timeDay}>Sunday Evening</p>
                  <p className={styles.timeDay} style={{fontStyle: "italic"}}>(No evening service the last Sunday of the month)</p>
                </div>
              </div>
              <p className={styles.meetingHeader}>Wednesday</p>
              <div className={styles.timeGridBottom}>
                <div className={styles.timeGrid_1}>
                  <p className={styles.timeCircle}>7:00pm</p>
                  <p className={styles.timeDay}>Prayer Meeting</p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className={styles.spanishMinistry}>
          <div className={styles.container}>
            <div></div>
          </div>
        </div>
        <div className={styles.affiliation}>
          <div className={styles.container}>
            {/*<ScrollAnimation animateIn="zoomIn">*/}
            <div className={styles.affiliationGrid_1}>
              <h2 className={styles.affiliation_title}>Affiliation</h2>
              <hr className={styles.affiliation_hr} />
            </div>
            <div className={styles.affiliationGrid}>
              <div className={styles.affiliationGrid_1}>
                <a href="https://www.firefellowship.org/" target="_blank" rel="noopener noreferrer">
                  <img src={foire} className={styles.foire} alt="fellowship-of-independant-reformed-evangelicals" />
                </a>
              </div>
              <div className={styles.affiliationGrid_1}>
                <a href="https://reformedbaptistnetwork.com/" target="_blank" rel="noopener noreferrer">
                  <img src={rbnet} className={styles.rbnet} alt="reformed-baptist-network" />
                </a>
              </div>
            </div>
            {/*</ScrollAnimation>*/}
          </div>
        </div>
        <div className={styles.visitUs}>
          <div className={styles.container}>
            <ScrollAnimation animateIn="fadeIn">
              <div className={styles.videoGrid}>
                <div className={styles.videoGrid_1}>
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13229.033397118246!2d-117.4417612!3d34.0115789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3589eb3702595433!2sReformed%20Baptist%20Church%20of%20Riverside!5e0!3m2!1sen!2sus!4v1576124844684!5m2!1sen!2sus"
                    className={styles.map}
                    style={{ border: 0 }}
                    width="600"
                    height="350"
                    frameBorder="0"
                    allowFullScreen={true}
                  ></iframe>
                </div>
                <div className={styles.videoGrid_2}>
                  <div className={styles.videoGrid_titleBlock}>
                    <h2 className={styles.videoGrid_title}>Visit Us</h2>
                    <hr className={styles.vg_hr} />
                  </div>
                  <p className={styles.videoGrid_paragraph}>
                    We'd love to hear from you!
                    <br />
                    <br />
                    Have a question or a comment? <br />
                    <br />
                    We want to hear it! Feel free to call, write, or email us. Or better yet, come join us for one of
                    our Sunday services.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <Footer />
        {this.state.loading && <ProgressBar />}
        <GA title="Reformed Baptist Church of Riverside" />
      </div>
    );
  }
}

export default Home;
